@import "./customcontent/colors.scss";

@font-face {
  font-family: centuryGothic;
  font-style: normal;
  font-weight: 400;
  src: url(century-gothic.woff);
  font-display: swap;
}

html, body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $white;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, "centuryGothic", "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
}

h2 {
  font-size: 60px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
  color: $white;
}

h3 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  color: $white;
}

a{
  text-decoration: none;
  color: $white;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.button {
  transition: all 1s ease;
  border-radius: 5px;
  border: none;
  color: $white;
  background-color: $lightgrey-elements;
  letter-spacing: 1px;
  font-size: 20px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 20px;
  cursor: pointer;
}

.button:hover {
  background-color: $lightgrey-hover;
}

.lightButton {
  background-color: $superlightgrey-elements;
}

.lightButton:hover {
  background-color: $superlightgrey-hover;
}

.smallIcon {
  font-size: 16px;
}

.iconbuttontext {
  margin-left: 5px;
}

.iconbuttontextlarge {
  margin-left: 10px;
}

.green {
  color: $green;
}

.greenbg {
  background-color: $greenhov;
}

.greenbghov:hover {
  background-color: $greenbghov;
}

.red {
  color: $red;
}

.redhov:hover {
  color: $redhov;
}

.redbghov:hover {
  background-color: $redbghov;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $modalBackground;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  max-width: 80%;
  max-height: 90%;
  background-color: $windowBackground;
  border: $black-border 2px solid;
  border-radius: 10px;
  z-index: 1001;
  min-height: 80px;
  min-width: 100px;
  padding: 10px;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialogContent {
  overflow: auto;
}

.dialog p {
  align-self: flex-start;
  padding: 0px 5px;
}

.dialog p:first-of-type {
  align-self: flex-start;
  padding: 0px 5px 10px 5px;
  margin: 0px 0px;
}

.closeIcon {
  align-self: flex-end;
  border-radius: 50%;
  padding: 5px;
  font-size: 20px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.closeIcon:hover {
  background: $lightgrey-hover;  
}

/* From here we act in "mobile mode" */
@media (max-width: 1650px) {
  .hidablebuttontext {
    display: none;
  }

  .button:hover {
    .hidablebuttontext {
      display: inline;
    }
  }
}

.loader {
  color: $white;
  font-size: 10px;
  margin: 50px auto 50px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.disabledLink {
  pointer-events: none;
}

@-webkit-keyframes load4 {
  0%, 100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%, 100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}