// Backgrounds
//background of the whole page
$background: #2d2d2d;

$windowBackground: rgb(36, 36, 36);
$windowBackgroundDark: rgb(25, 25, 25);

$modalBackground: rgba(20, 20, 20, 0.5);

// Borders
$black-border: rgb(25, 25, 25);

// Buttons, Drag & Drop
$lightgrey-elements: #494949;
$superlightgrey-elements: rgba($lightgrey-elements, 0.2);

// Font & icons
$white: whitesmoke;
$whiteTransparent: rgba(whitesmoke, 0.2);
$black: rgba(10, 10, 10, 0.95);
$green: #2BC05D;
$red: #F74242;

// Hover colors
//to hover red buttons/icons
$redhov: rgba(255, 0, 0, 0.945);
//to hover "normal" buttons in red
$redbghov: rgba(255, 0, 0, 0.384);
//to hover green buttons/icons
$greenhov: #1ea74c;
//to hover "normal" buttons in green
$greenbghov: #128f3c;
//to hover buttons
$lightgrey-hover: #8F8F8F;
$superlightgrey-hover: rgba($lightgrey-hover, 0.2);

// Searchbar
$searchShadow: #313131eb;

// Toggle 
$toggle: rgb(102, 102, 102);
